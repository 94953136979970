import React from 'react';
import {useIntl} from "react-intl";
import testimonials from "../images/testimonials.svg";
import quotation from "../images/quotation.svg";
import linkedin from "../images/linkedin.svg";
import {useLocalization} from "gatsby-theme-i18n";

const Testimonials = () => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});
    const {locale} = useLocalization()
    return (
        <div className='flex flex-col py-8 md:py-16 gap-4 relative items-center'>
            <div className='h-28 w-28 text-gold'>
                <img src={testimonials} alt='Testimonials' className='h-auto w-full'/>
            </div>
            <p className='font-ExtraBold text-5xl xl:text-6xl mb-8'>
                {f("testimonials")}<span className='text-gray-light'>.</span>
            </p>
            <div className='w-full px-6 md:px-14 gap-4 grid md:grid-cols-2 -mt-4 md:-mt-4 max-w-7xl'>

                <div className='flex flex-col gap-3 py-2.5 w-full'>
                    <div className='h-11 w-11'>
                        <img src={quotation} alt='Quotation' className='h-auto w-full'/>
                    </div>
                    <p className='font-LightItalic md:text-lg leading-relaxed text-black-transparent dark:text-white-transparent'>{f("testimonialAndres")}</p>
                    <div>
                        <p className='font-SemiBold tracking-wide  md:text-xl'>{f("fullNameAndres")}</p>
                        <p className='font-Medium tracking-wide text-sm md:text-md text-black-transparent dark:text-white-transparent'>{f("currentJobAndres")}</p>
                    </div>
                    <a target="_blank" rel='noreferrer'
                       href='https://www.linkedin.com/in/andr%C3%A9s-villag%C3%B3mez-7832aa181/'>
                        <img src={linkedin} alt='Linked In' width={16} height={16}/>
                    </a>
                </div>
                <div/>

                <div/>
                <div className='flex flex-col items-end gap-2.5 py-2.5 w-full'>
                    <div className='h-11 w-11'>
                        <img src={quotation} alt='Quotation' className='h-auto w-full'/>
                    </div>
                    <div className='flex flex-col items-end'>
                        <p className='font-LightItalic md:text-lg leading-relaxed text-black-transparent dark:text-white-transparent text-right'>{f("testimonialShenick")}</p>
                        {locale==="en" && <span className='font-LightItalic text-sm text-black-transparent dark:text-white-transparent'>Not available in English</span>}
                    </div>
                    <div>
                        <p className='font-SemiBold tracking-wide md:text-xl text-right'>{f("fullNameShenick")}</p>
                        <p className='font-Medium tracking-wide text-sm md:text-md text-black-transparent dark:text-white-transparent text-right'>{f("currentJobShenick")}</p>
                    </div>
                    <a target="_blank" rel='noreferrer'
                       href='https://www.linkedin.com/in/shenick-guzman-campos-05b375108/'>
                        <img src={linkedin} alt='Linked In' width={16} height={16}/>
                    </a>
                </div>

                <div className='flex flex-col gap-2.5 py-2.5 w-full'>
                    <div className='h-11 w-11'>
                        <img src={quotation} alt='Quotation' className='h-auto w-full'/>
                    </div>
                    <p className='font-LightItalic md:text-lg leading-relaxed text-black-transparent dark:text-white-transparent'>{f("testimonialSinuhe")}</p>
                    <div>
                        <p className='font-SemiBold tracking-wide md:text-xl'>{f("fullNameSinuhe")}</p>
                        <p className='font-Medium tracking-wide text-sm md:text-md text-black-transparent dark:text-white-transparent'>{f("currentJobSinuhe")}</p>
                    </div>
                    <a target="_blank" rel='noreferrer'
                       href='https://www.linkedin.com/in/yair-sinuh%C3%A9-g%C3%B3mez-valencia-b5a87913a/'>
                        <img src={linkedin} alt='Linked In' width={16} height={16}/>
                    </a>
                </div>
                <div/>

                <div/>
                <div className='flex flex-col items-end gap-2.5 py-2.5 w-full'>
                    <div className='h-11 w-11'>
                        <img src={quotation} alt='Quotation' className='h-auto w-full'/>
                    </div>
                    <p className='font-LightItalic md:text-lg leading-relaxed text-black-transparent dark:text-white-transparent text-right'>{f("testimonialSoto")}</p>
                    <div>
                        <p className='font-SemiBold tracking-wide md:text-xl text-right'>{f("fullNameSoto")}</p>
                        <p className='font-Medium tracking-wide text-sm md:text-md text-black-transparent dark:text-white-transparent text-right'>{f("currentJobSoto")}</p>
                    </div>
                    <a target="_blank" rel='noreferrer'
                       href='https://www.linkedin.com/in/eduardo-soto-betancourt-736b68135/'>
                        <img src={linkedin} alt='Linked In' width={16} height={16}/>
                    </a>
                </div>

                <div className='flex flex-col gap-2.5 py-2.5 w-full'>
                    <div className='h-11 w-11'>
                        <img src={quotation} alt='Quotation' className='h-auto w-full'/>
                    </div>

                    <div className='flex flex-col'>
                        <p className='font-LightItalic md:text-lg leading-relaxed text-black-transparent dark:text-white-transparent'>{f("testimonialTakeshi")}</p>
                        {locale==="es" && <span className='font-LightItalic text-sm text-black-transparent dark:text-white-transparent'>No disponible en Español</span>}
                    </div>
                    <div>
                        <p className='font-SemiBold tracking-wide md:text-xl'>{f("fullNameTakeshi")}</p>
                        <p className='font-Medium tracking-wide text-sm md:text-md text-black-transparent dark:text-white-transparent'>{f("currentJobTakeshi")}</p>
                    </div>
                    <a target="_blank" rel='noreferrer'
                       href='https://www.linkedin.com/in/g-takeshi-nakamura/'>
                        <img src={linkedin} alt='Linked In' width={16} height={16}/>
                    </a>
                </div>
                <div/>

                <div/>
                <div className='flex flex-col items-end gap-2.5 py-2.5 w-full'>
                    <div className='h-11 w-11'>
                        <img src={quotation} alt='Quotation' className='h-auto w-full'/>
                    </div>
                    <div className='flex flex-col items-end'>
                        <p className='font-LightItalic md:text-lg leading-relaxed text-black-transparent dark:text-white-transparent text-right'>{f("testimonialAlex")}</p>
                        {locale==="es" && <span className='font-LightItalic text-sm text-black-transparent dark:text-white-transparent'>No disponible en Español</span>}
                    </div>
                    <div>
                        <p className='font-SemiBold tracking-wide md:text-xl text-right'>{f("fullNameAlex")}</p>
                        <p className='font-Medium tracking-wide text-sm md:text-md text-black-transparent dark:text-white-transparent text-right'>{f("currentJobAlex")}</p>
                    </div>
                    <a target="_blank" rel='noreferrer'
                       href='https://www.linkedin.com/in/alextremo-08/'>
                        <img src={linkedin} alt='Linked In' width={16} height={16}/>
                    </a>
                </div>

                <div className='flex flex-col gap-2.5 py-2.5 w-full'>
                    <div className='h-11 w-11'>
                        <img src={quotation} alt='Quotation' className='h-auto w-full'/>
                    </div>
                    <p className='font-LightItalic md:text-lg leading-relaxed text-black-transparent dark:text-white-transparent'>{f("testimonialGil")}</p>
                    <div>
                        <p className='font-SemiBold tracking-wide md:text-xl'>{f("fullNameGil")}</p>
                        <p className='font-Medium tracking-wide text-sm md:text-md text-black-transparent dark:text-white-transparent'>{f("currentJobGil")}</p>
                    </div>
                    <a target="_blank" rel='noreferrer'
                       href='https://www.linkedin.com/in/arturo-gil-jacome/'>
                        <img src={linkedin} alt='Linked In' width={16} height={16}/>
                    </a>
                </div>
                <div/>

            </div>
        </div>
    );
};

export default Testimonials;
