import * as React from "react";
import {useIntl} from "react-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Loader from "../components/loader";
import Testimonials from "../components/testimonials"
import {useLocalization} from "gatsby-theme-i18n";

const TestimonialsPage = (props) => {
    const {formatMessage} = useIntl();
    const f = (id) => formatMessage({id});

    const {locale} = useLocalization()

    const [hasMounted, setHasMounted] = React.useState(false)
    React.useEffect(() => {
        setHasMounted(true)
    }, [])
    if (!hasMounted) {
        return (<Loader/>)
    }

    return (
        <Layout location={props.location} color='#FCD12A'>
            <SEO title={f("about")} lang={locale} description={f("descriptionMeta")}/>
            <Testimonials/>
        </Layout>
    );
};

export default TestimonialsPage;
